import '/assets/styles/modals/wallet-qr.scss';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import intervalToDuration from 'date-fns/intervalToDuration';
import padStart from 'lodash/padStart';
import classNames from 'classnames';

// Partials
import Loader from '/views/partials/loader';
import Btn from '/views/partials/btn';

// Services
import walletServices from '/services/wallet'

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useWallet } from '/utilities/wallet';

const WalletQrModal = ({ closeBtn, close }) => {
	const { updateAll: updateWallet } = useWallet();
	const _mounted = useRef(false);

	const [qrData, setQrData] = useState(null);
	const { openModal } = useModals();
	const timerCountRef = useRef(0);

	const [order, setOrder] = useState(null);
	const [timerCount, setTimerCount] = useState(0);
	const [checkIsBusy, setCheckIsBusy] = useState(false);
	const [timerEnded, setTimerEnded] = useState(false);
	const [formattedTimer, setFormattedTimer] = useState(null);
	const timerRef = useRef();

	const formatDuration = () => {
		const duration = intervalToDuration({ start: 0, end: timerCountRef.current * 1000 });
		return (`${padStart(duration.minutes.toString(), 2, '0')}:${padStart(duration.seconds.toString(), 2, '0')}`);
	}

	const stopTimer = () => {

	}

	const countTimer = () => {
		if(timerCountRef.current) {
			timerCountRef.current = timerCountRef.current - 1;
			setTimerCount(timerCountRef.current);
			setFormattedTimer(formatDuration());
		}
		else if(timerRef.current) {
			clearInterval(timerRef.current);
			setTimerEnded(true);
		}
	}

	const createQr = () => {
		setQrData(null);
		setFormattedTimer('');
		walletServices.generateQr().then((payload) => {
			setTimerEnded(false);
			setQrData(payload);

			stopTimer();
			timerCountRef.current = differenceInSeconds(new Date(payload.expired_at), new Date()) -1;
			setTimerCount(timerCountRef.current);
			setFormattedTimer(formatDuration());

			timerRef.current = setInterval(countTimer, 1000);
		}).catch((feedback) => {
			openModal('message', { feedback })
		})
	}

	useEffect(() => {
		_mounted.current = true;
		createQr();

		return () => {
			_mounted.current = false;
			stopTimer();
		}
	}, [])

	useEffect(() => {
		if(!checkIsBusy && !timerEnded && qrData?.qr_code) {
			setCheckIsBusy(true);
			walletServices.checkQrStatus(qrData?.qr_code).then((order) => {
				if(order) {
					stopTimer();
					updateWallet().then(() => {
						setOrder(order);
					}).catch((feedback) => {
						openModal('message', { feedback });
					})
				}
				else {
					setTimeout(() => {
						if(_mounted.current) {
							setCheckIsBusy(false);
						}
					}, 5000);
				}
			}).catch(() => {
				setTimeout(() => {
					if(_mounted.current) {
						setCheckIsBusy(false);
					}
				}, 5000);
			}).finally(() => {
			})
		}
	}, [checkIsBusy, timerCount, timerEnded, qrData])

	return (
		<>
			{closeBtn}

			<div className="qr-content">
				<strong className="qr-title">Ödeme Yap</strong>

				{order ?
					<div className="qr-success">
						<strong className="success-title">Siparişiniz alındı!</strong>
						<Btn className="success-closebtn primary" onClick={close}>Tamam</Btn>
					</div>
					:
					<>
						<p className="qr-description">
							<span className="description-icon">i</span> QR Kodu satın alma işlemlerinde kasaya okutabilirsin.
						</p>

						<div className={classNames('qr-codewrap', 'loader-container', { ended: timerEnded })}>
							<Loader loading={!qrData} />

							{qrData &&
								<QRCode
									className="codewrap-qr"
									size={256}
									viewBox={`0 0 256 256`}
									value={qrData.qr_code} />
							}
						</div>

						<div className="qr-timer">
							{formattedTimer}
						</div>

						<div className="qr-controls">
							<Btn
								disabled={!qrData || timerCount > 5}
								onClick={createQr}
								className="controls-renewbtn outline text"
								icon="return">
								Yenile
							</Btn>
							<Btn
								onClick={close}
								tag="link"
								href="account.wallet"
								className="controls-redirectbtn text nude">
								Cüzdanıma Git
							</Btn>
						</div>
					</>
				}

			</div>
		</>
	)
}

WalletQrModal.propTypes = {
	closeBtn: PropTypes.node,
}

WalletQrModal.modalConfig = {
	containerClass: 'modal-wallet-qr mobile-full',
}

export default WalletQrModal